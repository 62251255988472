import React from 'react'

export default function ScannerEditForm({ data, onClose, onUpdate, onSubmit }) {
  return (
    <form style={{ boxSizing: 'border-box' }}>
      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Scanner Code</label>
          <input className="form-control" value={data.scannerCode} disabled />
        </div>
        <div className="col-sm-6">
          <label>HW Version</label>
          <select
            className="form-control custom-select"
            name="hwVersion"
            value={data.hwVersion}
            onChange={onUpdate}
          >
            <option value={0}>0 - Bubble</option>
            <option value={1}>1 - Paint Pole</option>
            <option value={2}>2 - Locking</option>
            <option value={3}>3 - Weight Scale</option>
            <option value={4}>4 - 3 Sensor</option>
            <option value={5}>5 - V5</option>
            <option value={6}>6 - V6</option>
            <option value={40}>40 - V4 Weight Scale Override</option>
            <option value={50}>50 - V5 Weight Scale Override</option>
            <option value={60}>60 - V6 Weight Scale Override</option>
          </select>
        </div>
      </div>
      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Capture Type</label>
          <select
            className="form-control custom-select"
            name="captureType"
            value={data.captureType}
            onChange={onUpdate}
          >
            <option value={1}>1 - 1 sensor 1/2 frame</option>
            <option value={2}>2 - 1 sensor 1/1 frame</option>
            <option value={3}>3 - 3 sensor 1/8 frame</option>
            <option value={4}>4 - 3 sensor 1/6 frame</option>
          </select>
        </div>
      </div>
      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>System ID</label>
          <input
            className="form-control"
            name="systemId"
            value={data.systemId}
            onChange={onUpdate}
          />
        </div>
        <div className="col-sm-6">
          <label>Slider SN</label>
          <input
            className="form-control"
            name="sliderSn"
            value={data.sliderSn}
            onChange={onUpdate}
          />
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-sm-6">
          <label>Turntable SN</label>
          <input
            className="form-control"
            name="turntableSn"
            value={data.turntableSn}
            onChange={onUpdate}
          />
        </div>
        <div className="col-sm-6">
          <label>Control Module ID</label>
          <input
            className="form-control"
            name="controlModuleId"
            value={data.controlModuleId}
            onChange={onUpdate}
          />
        </div>
      </div>

      <div className="row px-3 mb-4">
        <div className="col-sm-6">
          <label>Registration Status</label>
          <select
            className="form-control custom-select"
            name="status"
            value={data.status}
            onChange={onUpdate}
          >
            <option value={1}>1 - Activated</option>
            <option value={5}>5 - Deactivated</option>
          </select>
        </div>
        <div className="col-sm-6">
          <label>Additional Metrics Enabled</label>
          <select
            className="form-control custom-select"
            name="additionalMetricsEnabled"
            value={data.additionalMetricsEnabled}
            onChange={onUpdate}
          >
            <option value={1}>1 - Enabled</option>
            <option value={5}>5 - Disabled</option>
          </select>
        </div>
      </div>

      <div className="row px-3">
        <div className="ml-auto">
          <button
            className="btn btn-default mr-3"
            type="button"
            onClick={onClose}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={onSubmit}>
            Update Scanner
          </button>
        </div>
      </div>
    </form>
  )
}
